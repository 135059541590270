@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+2&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css');
/* @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css'); */

/* 0b090a */
/* 161a1d */
/* 660708 */
/* a4161a      */
/* ba181b */
/* e5383b */
/* b1a7a6 */
/* d3d3d3 */
/* f5f3f4 */
/* ffffff */

:root {
  --primary: #274eaa;
  --secondary: #1d1616e9;
  --tertiary: #464b56;
  --quaternary: #e9ecef;
  --quinary: #ecf0f3;
  --background1: #fff;
  --background2: #e9ecef;
  --background3: #f2f5f7;
  --transition: all 0.3s linear;
  --light-shadow: 0 5px 15px rgba(60, 81, 184, 0.053);
  --card-shadow: 0 2px 8px 1px rgba(38, 58, 111, 0.1);
  --card-neomorphic: inset 6px 6px 6px #ecf0f3, inset -6px -6px 6px #ffffff;
  --card-gradient: #16357e8a;
  --card-gradient2: #16357ebe;
  --input1: #d1d9e6;
  --input2: #f9f9f9;
  --btn-gradient: #4676e6;
  --intro--bg: url('./assets/svg/introLight.svg');
  --bgBox1: linear-gradient(to left top, #1e46a2, rgba(255, 255, 255, 0.657));
  --boxCol: #bec7d5;
}

[data-theme='dark'] {
  --primary: #315dc2;
  --secondary: rgba(255, 255, 255, 0.916);
  --tertiary: #9fa0a3;
  --quaternary: #09090b5e;
  --quinary: #16181d;
  --background1: #1c1f26;
  --background2: #111111;
  --background3: #16181d;
  --transition: all 0.3s linear;
  --light-shadow: 0 5px 15px rgba(33, 64, 142, 0.053);
  --card-shadow: 0 2px 8px 1px rgba(45, 45, 159, 0.05);
  --card-neomorphic: inset 6px 6px 6px #292a2d, inset -6px -6px 6px #35363a;
  --card-gradient: #16357e8a;
  --card-gradient2: #16357ebe;
  --input1: #131417;
  --input2: #16181d;
  --btn-gradient: #4676e6;
  --bgBox1: linear-gradient(to left top, #1e46a2, rgba(0, 42, 251, 0));
  --boxCol: #242830;
  --intro--bg: url('./assets/svg/introDark.svg');
}

* {
  font-family: 'Baloo 2', 'Cairo', 'Lexend Deca', sans-serif;
  font-weight: semi-bold;
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  width: 100%;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  font-size: 0.875rem;
  -webkit-overflow-scrolling: touch;
  background-color: #111111;
}

.app {
  background: var(--background2) !important;
  width: 100%;
  height: 100%;
  color: var(--tertiary);
  -webkit-transform: var(--transition);
  -ms-transform: var(--transition);
  transform: var(--transition);
}

@media screen and (max-width: 1366px) {
  .overfx {
    overflow-x: hidden;
  }
}
.hidden {
  display: none !important;
}

/* NAV  */

#top,
#bottom {
  -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.5, 0, 0.2, 1);
  -o-transition: stroke-dashoffset 0.5s cubic-bezier(0.5, 0, 0.2, 1);
  transition: stroke-dashoffset 0.5s cubic-bezier(0.5, 0, 0.2, 1);
}

#middle {
  opacity: 1;
  -webkit-transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
  fill: none;
  stroke: var(--primary);
  stroke-width: 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-linejoin: round;
}

path {
  stroke-dasharray: 25 50;
  stroke-dashoffset: 0;
}

button {
  background: none;
  border: none;
}

.x #middle {
  opacity: 0;
}

.x #top,
.x #bottom {
  stroke-dashoffset: -45;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  text-transform: capitalize;
  line-height: 1;
  color: var(--secondary);
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2.5rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  cursor: default;
  margin-bottom: 1.25rem;
  color: var(----tertiary);
}
@media screen and (min-width: 768px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
div {
  cursor: default;
}
/*
=============== 
Navbar
===============
*/

.nav {
  background: var(--background1);
  -webkit-box-shadow: var(--light-shadow);
  box-shadow: var(--light-shadow);
  position: relative;
  z-index: 10000000001 !important;
}

#site-header {
  top: 0;
  width: 100%;
  margin-top: -2px;
  position: fixed;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  z-index: 100;
}
#site-header.hide {
  top: -100px;
}

.contener {
  width: 95%;
  margin: auto auto;
  margin-top: 139px;
}

.contenerAPP {
  width: 100%;
  margin: auto auto;
  /* margin-top: 139px; */
}
.nav-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.nav-toggle {
  background: transparent;
  border-color: transparent;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  cursor: pointer;
}
.links a {
  color: var(--tertiary);
  text-align: center;
  font-size: 1rem;
  text-transform: capitalize;
  display: block;
  padding: 0.2rem;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.divLink {
  color: var(--tertiary);
  text-align: center;
  font-size: 1.2rem;
  text-transform: capitalize;
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 0.4vw;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}

.links a:hover {
  color: var(--primary);
  text-align: center;
}
.divLink:hover {
  color: var(--primary);
  text-align: center;
  background-color: var(--quaternary);
  -webkit-transform: var(--transition);
  -ms-transform: var(--transition);
  transform: var(--transition);
  border-radius: 15px;
  cursor: pointer;
}
.divLink div:hover {
  cursor: pointer;
}

.links-container {
  height: 0;
  overflow: hidden;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.show-container {
  height: 10rem;
}
@media screen and (min-width: 768px) {
  .nav-center {
    max-width: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem;
    -webkit-transition: 5px;
    -o-transition: 5px;
    transition: 5px;
  }
  .nav-header {
    padding: 0px;
  }
  .nav-toggle {
    display: none;
  }
  .links-container {
    height: auto !important;
  }
  .links {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .links a {
    padding: 0;
    margin: 0 0.5rem;
  }
}

.linksbg a {
  font-size: 1.2rem;

  text-align: center;
}
.linksbg a:hover {
  color: var(--primary);
}

.cardiv {
  margin: auto auto;
  -webkit-transition: ease 0.2s;
  -o-transition: ease 0.2s;
  transition: ease 0.2s;
  display: block;
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  background: var(--background1);
  border-radius: 30px;
  cursor: pointer;
  padding: 15px;
  border: none;
}
@media screen and (max-width: 768px) {
  .logo {
    margin-right: -5px;
  }
}
span {
  font-size: 15px;
  color: var(--primary);
}
.logo span {
  font-family: 'Cairo', 'Tajawal', sans-serif;
}

.links-container.button button {
  margin: 10px auto;
  display: block;
  background-color: var(--primary);
  border: 1px solid transparent;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 2px;
}

.links-container.button button:hover {
  background-color: var(--background1);
  color: var(--primary);
  border-color: var(--primary);
}

.nav-centerAR {
  direction: rtl;
}

.nav-center {
  padding-left: 10px;
  padding-right: 10px;
}

.arFont {
  font-family: 'Cairo', sans-serif !important;
}

.modal-content {
  direction: ltr !important;
}

.divLinkSVG {
  width: 20px;
  fill: var(--secondary);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}

.langBtnContener {
  width: 70px;
  height: 100%;
}

.langBtnDiv1 {
  background: var(--primary);
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 8px;
  border: 3px solid var(--quinary);
  overflow: hidden;
  position: absolute;
  top: 20px;
  left: 21px;
}
.langBtnDivAR {
  font-size: 20px;
  margin-top: -8px;
  color: var(--quinary);
}
.langBtnDiv2 {
  background: var(--background1);
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 8px;
  border: 3px solid var(--quinary);
  position: absolute;
  top: 35px;
  left: 38px;
}

.langBtnDivEN {
  color: var(--primary);
  font-size: 20px;
  margin-top: -3px;
}

.langBtnDiv2EN {
  background: var(--background1);
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 8px;
  border: 3px solid var(--quinary);
  position: absolute;
  top: 35px;
  right: 38px;
  color: var(--primary);
}

.langBtnDiv1EN {
  background: var(--primary);
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 8px;
  border: 3px solid var(--quinary);
  overflow: hidden;
  position: absolute;
  top: 20px;
  right: 21px;
}

.langBtnContener div:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .langBtnContener {
    height: 70px;
    padding-top: -32px;
  }
  .langBtnContenerMobile {
    width: 100%;
    position: relative;
    height: 100%;
    margin-top: -15px;
  }
}
/*
=============== 
 Card Slider
===============
*/

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  background: var(--background2);
}

.swiper-slide {
  border-radius: 30px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper {
  width: 100%;
  height: 100% !important;
  margin: 15px;
  border-radius: 30px !important;
}

.banalImg {
  width: 100vw;
  height: 38vw;
  border-radius: 30px !important;
}

body > #root > div {
  height: 100% !important;
  min-height: 100vh;
  overflow: hidden;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}

.themeContener {
  text-align: center;
  font-size: 1.2rem;
  text-transform: capitalize;
  display: block;
  background: var(--background1);
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 3px solid var(--quinary);
  padding-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  padding: 5px;
  margin: 2px 7px 0 7px;
}

@media screen and (max-width: 768px) {
  .themeContenerMobile {
    width: 100%;
    position: relative;
    height: 0.1px;
  }
  .themeContener {
    margin-bottom: -1px;
  }
}

@media screen and (max-width: 768px) {
  .themefixAR {
    position: absolute;
    bottom: -46px;
    left: -5px;
  }
}
@media screen and (max-width: 768px) {
  .themefix {
    position: absolute;
    bottom: -46px;
    right: -5px;
  }
}

.introContener {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 100px;
}
.introDivL {
  width: 47%;
}

.introDivR {
  width: 47%;
  /* background-image: var(--intro--bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
}

.introH2 {
  color: var(--primary);
  margin: 20px;
  font-size: 30px;
  text-align: center;
}
.introH2 {
  color: var(--primary);
  font-size: 30px;
  text-align: center;
  padding: 10px;
}
/* @keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 100%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} */

.introDivBorder {
  width: 50%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  background: -o-linear-gradient(
    135deg,
    var(--card-gradient),
    var(--background2),
    var(--background2),
    var(--card-gradient)
  );
  background: linear-gradient(
    -45deg,
    var(--card-gradient),
    var(--background2),
    var(--background2),
    var(--card-gradient)
  );
  background-size: 400% 400%;
  /* animation: gradient 15s ease infinite; */
  border-radius: 30px;
  margin: 50px auto;
  height: 20px;
}
@media screen and (max-width: 768px) {
  .introDivBorder {
    width: 80%;
  }
}
.introText {
  color: var(--secondary);
  margin-top: 10px;
  font-size: 18px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .introContener {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .introDivL {
    width: 100%;
  }

  .introDivR {
    width: 100%;
  }
}

.btn-hover {
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 55px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover {
  background-image: -o-linear-gradient(
    left,
    var(--primary),
    var(--btn-gradient),
    var(--btn-gradient),
    var(--primary)
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--primary)),
    color-stop(var(--btn-gradient)),
    color-stop(var(--btn-gradient)),
    to(var(--primary))
  );
  background-image: linear-gradient(
    to right,
    var(--primary),
    var(--btn-gradient),
    var(--btn-gradient),
    var(--primary)
  );
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:active {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -o-transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
}
.btn-hover:before,
.btn-hover:after {
  -webkit-transition: all ease-in-out 0.5s;
  -o-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -o-transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  -webkit-transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: -webkit-transform ease-in-out 0.1s,
    -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s,
    -webkit-transform ease-in-out 0.1s, -webkit-box-shadow ease-in-out 0.25s;
}

.introBtnContener {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.introDivL {
  min-height: 500px;
}
/* hero */

.container,
.container-sm {
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 481px) {
  .container,
  .container-sm {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.container {
  max-width: 1128px;
}
.container-sm {
  max-width: 848px;
}
.container .container-sm {
  max-width: 800px;
  padding-left: 0;
  padding-right: 0;
}
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  word-wrap: normal !important;
}
.screen-reader-text:focus {
  border-radius: 2px;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  display: block;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none;
  text-transform: uppercase;
  background-color: #1d2026;
  color: #0270d7 !important;
  border: none;
  height: auto;
  left: 8px;
  padding: 16px 32px;
  top: 8px;
  width: auto;
  z-index: 100000;
}
.list-reset {
  list-style: none;
  padding: 0;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-primary {
  color: #0270d7;
}
.has-top-divider {
  position: relative;
}
.has-top-divider::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: #242830;
}
.has-bottom-divider {
  position: relative;
}
.has-bottom-divider::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
}
.m-0 {
  margin: 0;
}
.mt-0 {
  margin-top: 0;
}
.mr-0 {
  margin-right: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.ml-0 {
  margin-left: 0;
}
.m-8 {
  margin: 8px;
}
.mt-8 {
  margin-top: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.m-16 {
  margin: 16px;
}
.mt-16 {
  margin-top: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.m-24 {
  margin: 24px;
}
.mt-24 {
  margin-top: 24px;
}
.mr-24 {
  margin-right: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.m-32 {
  margin: 32px;
}
.mt-32 {
  margin-top: 32px;
}
.mr-32 {
  margin-right: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.ml-32 {
  margin-left: 32px;
}
.m-40 {
  margin: 40px;
}
.mt-40 {
  margin-top: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.m-48 {
  margin: 48px;
}
.mt-48 {
  margin-top: 48px;
}
.mr-48 {
  margin-right: 48px;
}
.mb-48 {
  margin-bottom: 48px;
}
.ml-48 {
  margin-left: 48px;
}
.m-56 {
  margin: 56px;
}
.mt-56 {
  margin-top: 56px;
}
.mr-56 {
  margin-right: 56px;
}
.mb-56 {
  margin-bottom: 56px;
}
.ml-56 {
  margin-left: 56px;
}
.m-64 {
  margin: 64px;
}
.mt-64 {
  margin-top: 64px;
}
.mr-64 {
  margin-right: 64px;
}
.mb-64 {
  margin-bottom: 64px;
}
.ml-64 {
  margin-left: 64px;
}
.p-0 {
  padding: 0;
}
.pt-0 {
  padding-top: 0;
}
.pr-0 {
  padding-right: 0;
}
.pb-0 {
  padding-bottom: 0;
}
.pl-0 {
  padding-left: 0;
}
.p-8 {
  padding: 8px;
}
.pt-8 {
  padding-top: 8px;
}
.pr-8 {
  padding-right: 8px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pl-8 {
  padding-left: 8px;
}
.p-16 {
  padding: 16px;
}
.pt-16 {
  padding-top: 16px;
}
.pr-16 {
  padding-right: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pl-16 {
  padding-left: 16px;
}
.p-24 {
  padding: 24px;
}
.pt-24 {
  padding-top: 24px;
}
.pr-24 {
  padding-right: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}
.pl-24 {
  padding-left: 24px;
}
.p-32 {
  padding: 32px;
}
.pt-32 {
  padding-top: 32px;
}
.pr-32 {
  padding-right: 32px;
}
.pb-32 {
  padding-bottom: 32px;
}
.pl-32 {
  padding-left: 32px;
}
.p-40 {
  padding: 40px;
}
.pt-40 {
  padding-top: 40px;
}
.pr-40 {
  padding-right: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pl-40 {
  padding-left: 40px;
}
.p-48 {
  padding: 48px;
}
.pt-48 {
  padding-top: 48px;
}
.pr-48 {
  padding-right: 48px;
}
.pb-48 {
  padding-bottom: 48px;
}
.pl-48 {
  padding-left: 48px;
}
.p-56 {
  padding: 56px;
}
.pt-56 {
  padding-top: 56px;
}
.pr-56 {
  padding-right: 56px;
}
.pb-56 {
  padding-bottom: 56px;
}
.pl-56 {
  padding-left: 56px;
}
.p-64 {
  padding: 64px;
}
.pt-64 {
  padding-top: 64px;
}
.pr-64 {
  padding-right: 64px;
}
.pb-64 {
  padding-bottom: 64px;
}
.pl-64 {
  padding-left: 64px;
}
.sr .has-animations .is-revealing {
  visibility: hidden;
}
.has-animations .anime-element {
  visibility: hidden;
}
.anime-ready .has-animations .anime-element {
  visibility: visible;
}

.input {
  -moz-appearance: none;
  -webkit-appearance: none;
  font-size: 16px;
  letter-spacing: -0.1px;
  line-height: 20px;
  padding: 13px 16px;
  height: 48px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.input .inline-input {
  display: inline;
  width: auto;
}
.textarea {
  display: block;
  min-width: 100%;
  resize: vertical;
}
.textarea .inline-textarea {
  display: inline;
  width: auto;
}
.field-grouped > .control:not(:last-child) {
  margin-bottom: 8px;
}
@media (min-width: 641px) {
  .field-grouped {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .field-grouped > .control {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .field-grouped > .control.control-expanded {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }
  .field-grouped > .control:not(:last-child) {
    margin-bottom: 0;
    margin-right: 8px;
  }
}
.button {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none !important;
  text-transform: uppercase;
  background-color: #242830;
  color: #fff !important;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 16px 32px;
  height: 48px;
  text-align: center;
  white-space: nowrap;
}
.button:hover {
  background: #262a33;
}
.button:active {
  outline: 0;
}
.button::before {
  border-radius: 2px;
}
.button-sm {
  padding: 8px 24px;
  height: 32px;
}

.button-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.button-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
@media (max-width: 640px) {
  .button-wide-mobile {
    width: 100%;
    max-width: 280px;
  }
}
.site-header {
  padding: 24px 0;
}
.site-header-inner {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-links {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.header-links li {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.header-links a:not(.button) {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-weight: 600;
  color: #8a94a7;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 16px;
  padding: 8px 24px;
}
@media (min-width: 641px) {
  .site-header {
    position: relative;
  }
  .site-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 700px;
    background: var(--background3);
    -webkit-transform-origin: 0;
    -ms-transform-origin: 0;
    transform-origin: 0;
    -webkit-transform: skewY(-12deg);
    -ms-transform: skewY(-12deg);
    transform: skewY(-12deg);
  }
}
.hero {
  text-align: center;
  padding-top: 48px;
  padding-bottom: 88px;
}
.hero-copy {
  position: relative;
  z-index: 1;
}
.hero-copyAR {
  position: relative;
  z-index: 1;
}
.hero-cta {
  margin-bottom: 40px;
}
.hero-figure {
  position: relative;
}
.hero-figure svg {
  width: 100%;
  height: auto;
}
.hero-figure::before,
.hero-figure::after {
  content: '';
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100%;
}
.has-animations .hero-figure::before,
.has-animations .hero-figure::after {
  opacity: 0;
  -webkit-transition: opacity 2s ease;
  -o-transition: opacity 2s ease;
  transition: opacity 2s ease;
}
.anime-ready .has-animations .hero-figure::before,
.anime-ready .has-animations .hero-figure::after {
  opacity: 1;
}
.hero-figure::before {
  top: -57.8%;
  left: -1.3%;
  width: 152.84%;
  height: 178.78%;
  background-image: url('./assets/svg/hero-back-illustration.svg');
}
.hero-figure::after {
  top: -35.6%;
  left: 99.6%;
  width: 57.2%;
  height: 87.88%;
}
.hero-figure-box {
  position: absolute;
  top: 0;
  will-change: transform;
}
.hero-figure-box-01,
.hero-figure-box-02,
.hero-figure-box-04,
.hero-figure-box-08,
.hero-figure-box-09 {
  overflow: hidden;
}
.hero-figure-box-01::before,
.hero-figure-box-02::before,
.hero-figure-box-04::before,
.hero-figure-box-08::before,
.hero-figure-box-09::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.hero-figure-box-01 {
  left: 103.2%;
  top: 41.9%;
  width: 28.03%;
  height: 37.37%;
  background: var(--bgBox1);
  -webkit-transform: rotateZ(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotateZ(-45deg);
  rotate: 45deg;
}
.hero-figure-box-01::before {
  background: -o-linear-gradient(
    right,
    var(--background2) 0%,
    rgba(21, 24, 29, 0) 60%
  );
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(var(--background2)),
    color-stop(60%, rgba(21, 24, 29, 0))
  );
  background: linear-gradient(
    to left,
    var(--background2) 0%,
    rgba(21, 24, 29, 0) 60%
  );
  -webkit-transform: rotateZ(45deg) scale(1.5);
  -ms-transform: rotate(45deg) scale(1.5);
  transform: rotateZ(45deg) scale(1.5);
}

.hero-figure-box-02 {
  left: 61.3%;
  top: 64.1%;
  width: 37.87%;
  height: 50.5%;
  background: var(--bgBox1);
  -webkit-transform: rotateZ(45deg);
  -ms-transform: rotate(45deg);
  transform: rotateZ(45deg);
  rotate: -45deg;
}
.hero-figure-box-02::before {
  background: -o-linear-gradient(
    bottom,
    var(--background2) 0%,
    rgba(21, 24, 29, 0) 60%
  );
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(var(--background2)),
    color-stop(60%, rgba(21, 24, 29, 0))
  );
  background: linear-gradient(
    to top,
    var(--background2) 0%,
    rgba(21, 24, 29, 0) 60%
  );
  -webkit-transform: rotateZ(-45deg) scale(1.5);
  -ms-transform: rotate(-45deg) scale(1.5);
  transform: rotateZ(-45deg) scale(1.5);
}

.hero-figure-box-04 {
  left: 54.9%;
  top: -8%;
  width: 45.45%;
  height: 60.6%;
  background: var(--bgBox1);
  -webkit-transform: rotateZ(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotateZ(-135deg);
  rotate: -135deg;
}
.hero-figure-box-04::before {
  background: -o-linear-gradient(
    bottom,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0) 60%
  );
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(255, 255, 255, 0.24)),
    color-stop(60%, rgba(255, 255, 255, 0))
  );
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0) 60%
  );
  -webkit-transform: rotateZ(-45deg) scale(1.5);
  -ms-transform: rotate(-45deg) scale(1.5);
  transform: rotateZ(-45deg) scale(1.5);
}
.hero-figure-box-05,
.hero-figure-box-06,
.hero-figure-box-07 {
  background-color: var(--boxCol);
  -webkit-box-shadow: -20px 32px 64px rgba(0, 0, 0, 0.25);
  box-shadow: -20px 32px 64px rgba(0, 0, 0, 0.25);
}
.hero-figure-box-05 {
  left: 17.4%;
  top: 13.3%;
  width: 64%;
  height: 73.7%;
  /* transform: perspective(500px) rotateY(-15deg) rotateX(80deg) rotateZ(-1deg); */
}
.hero-figure-box-06 {
  left: 65.5%;
  top: 6.3%;
  width: 30.3%;
  height: 40.4%;
  -webkit-transform: rotateZ(20deg);
  -ms-transform: rotate(20deg);
  transform: rotateZ(20deg);
  rotate: 20deg;
}
.hero-figure-box-07 {
  left: 1.9%;
  top: 42.4%;
  width: 12.12%;
  height: 16.16%;
  -webkit-transform: rotateZ(20deg);
  -ms-transform: rotate(20deg);
  transform: rotateZ(20deg);
  rotate: 20deg;
}
.hero-figure-box-08 {
  left: 27.1%;
  top: 81.6%;
  width: 19.51%;
  height: 26.01%;
  background: var(--primary);
  -webkit-transform: rotateZ(-22deg);
  -ms-transform: rotate(-22deg);
  transform: rotateZ(-22deg);
  rotate: -22deg;
}
.hero-figure-box-08::before {
  background: -o-linear-gradient(
    right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.48) 100%
  );
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(255, 255, 255, 0)),
    to(rgba(255, 255, 255, 0.48))
  );
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.48) 100%
  );
  -webkit-transform: rotateZ(45deg) scale(1.5);
  -ms-transform: rotate(45deg) scale(1.5);
  transform: rotateZ(45deg) scale(1.5);
}
.hero-figure-box-09 {
  left: 42.6%;
  top: -17.9%;
  width: 6.63%;
  height: 8.83%;
  background: var(--card-gradient);
  -webkit-transform: rotateZ(-52deg);
  -ms-transform: rotate(-52deg);
  transform: rotateZ(-52deg);
  rotate: -52deg;
}
.hero-figure-box-09::before {
  background: -o-linear-gradient(
    right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.64) 100%
  );
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(255, 255, 255, 0)),
    to(rgba(255, 255, 255, 0.64))
  );
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.64) 100%
  );
  -webkit-transform: rotateZ(45deg) scale(1.5);
  -ms-transform: rotate(45deg) scale(1.5);
  transform: rotateZ(45deg) scale(1.5);
}
.hero-figure-box-10 {
  left: -3.8%;
  top: 4.3%;
  width: 3.03%;
  height: 4.04%;
  background: var(--btn-gradient);
  -webkit-transform: rotateZ(-50deg);
  -ms-transform: rotate(-50deg);
  transform: rotateZ(-50deg);
  rotate: -50deg;
}
@media (max-width: 640px) {
  .hero-cta {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }
  .hero-cta .button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .hero-cta .button + .button {
    margin-top: 16px;
  }
  .hero-figure::after,
  .hero-figure-box-04,
  .hero-figure-box-09 {
    display: none;
  }
}
@media (min-width: 641px) {
  .hero {
    text-align: left;
    padding-top: 64px;
    padding-bottom: 88px;
  }
  .hero-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .hero-copy {
    padding-right: 64px;
    min-width: 552px;
    width: 552px;
  }
  .hero-copyAR {
    padding-left: 64px;
    min-width: 552px;
    width: 552px;
  }
  .hero-cta {
    margin: 0;
  }
  .hero-cta .button {
    min-width: 170px;
  }
  .hero-cta .button:first-child {
    margin-right: 16px;
  }
  .hero-figure svg {
    width: auto;
  }
}
.features-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  margin-right: -32px;
  margin-left: -32px;
}
.features-wrap:first-of-type {
  margin-top: -16px;
}
.features-wrap:last-of-type {
  margin-bottom: -16px;
}
.feature {
  padding: 16px;
  width: 100%;
  max-width: 400px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.feature-inner {
  height: 100%;
  width: 100%;
}
.feature-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media (min-width: 641px) {
  .features-wrap:first-of-type {
    margin-top: -24px;
  }
  .features-wrap:last-of-type {
    margin-bottom: -24px;
  }
  .feature {
    /* padding: 32px 32px; */
  }
}
.pricing-header {
  margin-bottom: 48px;
}
.pricing-tables-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: -12px;
  margin-left: -12px;
}
.pricing-tables-wrap:first-child {
  margin-top: -12px;
}
.pricing-tables-wrap:last-child {
  margin-bottom: -12px;
}
.pricing-table {
  position: relative;
  padding: 12px;
  width: 368px;
  max-width: 368px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.pricing-table::before {
  content: '';
  position: absolute;
  left: 50%;
  width: 200%;
  max-width: 200%;
  height: 435px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  bottom: 18.8%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background-image: url('./assets/svg/pricing-illustration.svg');
}
.pricing-table-header,
.pricing-table-features-title,
.pricing-table-features li {
  border-bottom: 1px solid rgba(138, 148, 167, 0.24);
}
.pricing-table-inner {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: #2c3039;
  padding: 24px;
  height: 100%;
}
.pricing-table-inner > * {
  position: relative;
  width: 100%;
}
.pricing-table-inner::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-box-shadow: 0 24px 48px rgba(21, 24, 29, 0.24);
  box-shadow: 0 24px 48px rgba(21, 24, 29, 0.24);
  mix-blend-mode: multiply;
}
.pricing-table-price-currency {
  color: #8a94a7;
}
.pricing-table-features-title {
  color: #fff;
  font-weight: 700;
}
.pricing-table-features li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 14px 0;
}
.pricing-table-features li::before {
  content: '';
  width: 16px;
  height: 12px;
  margin-right: 16px;
  /* background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUuNiA2LjRMMS42IDQgMCA1LjYgNS42IDEyIDE2IDEuNiAxNC40IDB6IiBmaWxsPSIjMDJDQkIxIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=); */
  background-repeat: no-repeat;
}
.pricing-table-cta {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}
@media (min-width: 641px) {
  .pricing .section-paragraph {
    padding-left: 90px;
    padding-right: 90px;
  }
  .pricing-header {
    margin-bottom: 52px;
  }
}
.cta {
  text-align: center;
}
.cta .section-inner {
  padding: 48px 16px;
}
.cta .section-title {
  margin-bottom: 40px;
}
.cta-inner {
  position: relative;
  background: #15181d;
  overflow: hidden;
}
.cta-inner::before {
  content: '';
  position: absolute;
  right: 98px;
  top: -117px;
  width: 160px;
  height: 187px;
  /* background-image: url('../images/cta-illustration.svg'); */
  background-repeat: no-repeat;
}
.cta-inner > * {
  position: relative;
}
@media (min-width: 641px) {
  .cta {
    text-align: left;
  }
  .cta .section-inner {
    padding: 64px 32px;
  }
  .cta .section-title {
    margin-bottom: 0;
    padding-right: 24px;
  }
  .cta-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.is-boxed {
  background: #242830;
}
.body-wrap {
  /* background: #1d2026; */
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

.boxed-container {
  max-width: 1440px;
  margin: 0 auto;
  -webkit-box-shadow: 0 24px 48px rgba(21, 24, 29, 0.24);
  box-shadow: 0 24px 48px rgba(21, 24, 29, 0.24);
  mix-blend-mode: multiply;
  mix-blend-mode: normal;
}
main {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
.section-inner {
  position: relative;
  padding-top: 48px;
  padding-bottom: 48px;
}
@media (min-width: 641px) {
  .section-inner {
    padding-top: 88px;
    padding-bottom: 88px;
  }
}
.site-footer {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
}
.site-footer a {
  color: #8a94a7;
  text-decoration: none;
}
.site-footer a:hover,
.site-footer a:active {
  text-decoration: underline;
}
.site-footer-inner {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 48px;
  padding-bottom: 48px;
}
.footer-brand,
.footer-links,
.footer-social-links,
.footer-copyright {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  width: 100%;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.footer-brand,
.footer-links,
.footer-social-links {
  margin-bottom: 24px;
}
.footer-social-links li {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.footer-social-links li + li {
  margin-left: 16px;
}
.footer-social-links li a {
  padding: 8px;
}
.footer-links li + li {
  margin-left: 24px;
}
@media (min-width: 641px) {
  .site-footer {
    margin-top: 20px;
  }
  .site-footer-inner {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .footer-brand,
  .footer-links,
  .footer-social-links,
  .footer-copyright {
    -webkit-box-flex: 50%;
    -webkit-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
  }
  .footer-brand,
  .footer-copyright {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .footer-links,
  .footer-social-links {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .footer-links {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    margin-bottom: 0;
  }
}

.hero-title {
  padding: 2px;
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
  color: var(--secondary);
}
.hero-paragraph {
  padding: 5px 10px;
  text-align: center;
  font-size: 18px;
}

.BannerBg {
  background: var(--background3);
  width: 100%;
  height: 100%;
  padding: 115px 30px 0 30px;
}
.standerContener {
  width: 90%;
  max-width: 1128px;
  margin: auto auto;
  margin-top: 119px;
  margin-bottom: 50px;
}

.fade-fix {
  background: var(--background3);
  height: 50px;
  margin-top: -15px;
}

/* svg */
svg #freepik_stories-building-safety:not(.animated) .animable {
  opacity: 0;
}
svg #freepik_stories-building-safety.animated #freepik--Floor--inject-9 {
  -webkit-animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) fadeIn;
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) fadeIn;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
svg #freepik_stories-building-safety.animated #freepik--Clouds--inject-9 {
  -webkit-animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) fadeIn,
    3s Infinite linear floating;
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) fadeIn,
    3s Infinite linear floating;
  -webkit-animation-delay: 0s, 1s;
  animation-delay: 0s, 1s;
}
svg #freepik_stories-building-safety.animated #freepik--Building--inject-9 {
  -webkit-animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) fadeIn;
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) fadeIn;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
svg
  #freepik_stories-building-safety.animated
  #freepik--magnifying-glass--inject-9 {
  -webkit-animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) zoomOut;
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) zoomOut;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  opacity: 0;
}
svg #freepik_stories-building-safety.animated #freepik--Crane--inject-9 {
  -webkit-animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38)
    slideLeft;
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideLeft;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  opacity: 0;
}
svg #freepik_stories-building-safety.animated #freepik--Shield--inject-9 {
  -webkit-animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38)
    slideLeft;
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideLeft;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  opacity: 0;
}
svg #freepik_stories-building-safety.animated #freepik--Character--inject-9 {
  -webkit-animation: 0.5s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38)
    slideRight;
  animation: 0.5s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideRight;
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
  opacity: 0;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes floating {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes floating {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-webkit-keyframes zoomOut {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes zoomOut {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes slideLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes slideRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@media (max-width: 641px) {
  .BannerBg {
    background: var(--background2);
    padding: 115px 10px 0 10px;
  }
  .fade-fix {
    background-color: var(--background2);
    height: 0;
    margin-bottom: -50px;
  }
  .BannerContener {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .logo h2 {
    font-size: 16px;
  }
  .logo span {
    font-size: 16px;
  }
}

.servicesHederContener {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.servicesHederTitle {
  padding-bottom: 20px;
  margin-bottom: 10px;
  border-bottom: 5px solid var(--btn-gradient);

  border-radius: 10px;
}

.feature-title {
  font-size: 20px;
  margin-bottom: 20px;
}

.feature-inner:hover {
  -webkit-box-shadow: 0.5px 0.5px 4px 4px var(--card-gradient);
  box-shadow: 0.5px 0.5px 4px 4px var(--card-gradient);
  cursor: pointer;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.ProjectContenerDiv {
  width: 95%;
  max-width: 1128px;
  margin: 0 auto;
  overflow: hidden !important;
  margin-bottom: 100px;
}
.landingProjectContener {
  max-height: 500px;
  /* background-color: #0270d7; */
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden !important;
  border-radius: 30px;
}
.BannerContener2 {
  width: 100%;
}

.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet,
.swiper-vertical
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  background: var(--background2);
}

.projectImgBtnContener {
  background: var(--background1);
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-radius: 30px;
  /*  */
  background-color: var(--background1);
}

.projectImg {
  height: 100%;
  width: 100%;
  border-radius: 30px;
}

.projectImgBtn {
  position: absolute;
  bottom: 15px;
}

.PartnersContener {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.PartnersContener div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.PartnersImg {
  max-width: 250px;
  max-height: 150px;
  width: 100%;
  height: 100%;
  margin: auto auto !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  /* background-color: #fff;
  border-radius: 50%;
  padding: 20px; */
  padding: 0 10px;
}

.PartnersImg img {
  margin: auto auto !important;
}

/* 

;e;e;e;


*/

.footerContener {
  background-color: var(--background1);
}

.footers {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
@media screen and (max-width: 768px) {
  .footers {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}
.footersL {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.footersR {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.footersL1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.footerLink:hover {
  cursor: pointer;
}
.Powerd {
  padding: 10px 0;
  margin-top: -5px;
  text-align: center;
  color: var(--tertiary);
  padding-bottom: 15px;
}
.footerDiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 20px;
}

/* ///////// */

a {
  text-decoration: none;
  color: var(--primary);
}
a:hover {
  color: var(--primary);
}
.social-icons {
  padding: 0;
  list-style: none;
  margin: 0.5em;
}
.social-icons li {
  display: inline-block;
  margin: 0.15em;
  position: relative;
  font-size: 1.2em;
}
.social-icons i {
  color: #f3f2ec;
  position: absolute;
  top: 10px;
  left: 12.5px;
  -webkit-transition: all 265ms ease-out;
  -o-transition: all 265ms ease-out;
  transition: all 265ms ease-out;
}

.social-icons a {
  display: inline-block;
}

.socialContener {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 20%;
  width: 100%;
  margin-top: 10px;
}

.social-icons a:before {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  content: ' ';
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: block;
  background: -o-linear-gradient(45deg, var(--btn-gradient), var(--primary));
  background: linear-gradient(45deg, var(--btn-gradient), var(--primary));
  -webkit-transition: all 265ms ease-out;
  -o-transition: all 265ms ease-out;
  transition: all 265ms ease-out;
}
.social-icons a:hover:before {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 265ms ease-in;
  -o-transition: all 265ms ease-in;
  transition: all 265ms ease-in;
}
.social-icons a:hover i {
  transform: scale(2.2);
  -ms-transform: scale(2.2);
  -webkit-transform: scale(2.2);
  color: var(--green);
  background: -o-linear-gradient(45deg, var(--btn-gradient), var(--primary));
  background: linear-gradient(45deg, var(--btn-gradient), var(--primary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transition: all 265ms ease-in;
  -o-transition: all 265ms ease-in;
  transition: all 265ms ease-in;
}
.social-icons a.social-square:before {
  background: -o-linear-gradient(45deg, var(--btn-gradient), var(--primary));
  background: linear-gradient(45deg, var(--btn-gradient), var(--primary));
  border-radius: 10%;
}
.social-icons a.social-square:hover:before {
  transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -webkit-transform: scale(-180deg);
  border-radius: 100%;
}
.social-icons a.social-square:hover i {
  transform: scale(1.6);
  -ms-transform: scale(1.6);
  -webkit-transform: scale(1.6);
  color: #fff;
  transform: scale(1.6);
  -webkit-text-fill-color: #fff;
}

@media screen and (max-width: 768px) {
  .footers {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .footersL1 ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
  }
  .footerLink {
    margin: 0 2vw;
  }
}

.social-icons a:before {
  margin: 0 5px;
}

.faContener {
  /* background-color: #0270d7;
  text-align: center;
  width: 100%;
  height: 100%;
  padding-left: 10px; */
}

.fa-brands {
  padding-left: 3px;
}

.fa-regular {
  padding-left: 2px;
}
.fa-facebook-f {
  padding-left: 5px;
}
.fa-youtube {
  padding-left: 1px;
}
.footersR2Div {
  color: var(--tertiary);
}

.footersR1 {
  margin: 0;
  height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 9px;
  margin-top: 10px;
}
.footersR2 {
  margin: 0;
  height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.footersL2 {
  margin: 0;
  height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.footersL1 {
  margin: 0;
  height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 968px) {
  .footersR1 {
    height: 100px;
  }
}

@media screen and (max-width: 768px) {
  .footersR1 {
    margin-top: 45px;
    max-width: 250px;
    margin-bottom: 30px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 50px;
  }
}

.smartCloudContener {
  color: var(--tertiary);
  margin-left: 5px;
}

.smartCloudAR {
  font-family: 'Cairo', 'Tajawal', sans-serif;
  margin-bottom: -7px;
  font-size: 15px;
  cursor: pointer;
}
.smartCloudEN {
  font-size: 15.5px;
  cursor: pointer;
}

/* wfc;lcm;wlefmc;lwmf;ewqm;; */
/* welml;ml;wem;dmwel;md;l */

/* about */

.aboutContener {
  text-align: center;
}
.aboutCardsContener {
  margin-top: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.aboutCardsThree {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.aboutCardsOne {
  width: 70%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.aboutDiv {
  height: 100%;
  margin: 8px;
  border: 1px solid var(--card-gradient);
}
.aboutDivCenter {
  background-color: var(--primary);
  background-image: -o-linear-gradient(
    45deg,
    var(--btn-gradient),
    var(--card-gradient)
  );
  background-image: linear-gradient(
    45deg,
    var(--btn-gradient),
    var(--card-gradient)
  );
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.aboutSVG {
  width: 100px;
  /* margin-bottom: 20px; */
}

.aboutcard {
  min-height: 360px;
  width: 100%;
}
.aboutcard:first-child {
  margin-right: 20px;
}
.aboutCardsOneS {
  display: none;
}

.searchIcon {
  width: 100px;
}

@media (max-width: 767px) {
  .aboutCardsContener {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .aboutcard {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    margin-bottom: 20px;
  }
  .aboutcard:first-child {
    margin-right: 0;
  }
  .aboutCardsOne {
    display: none;
  }
  .aboutCardsOneS {
    display: unset;
    width: 100%;
    height: 70px;
    margin-bottom: 16px;
  }

  .aboutDivCenter h2 {
    font-size: 20px;
  }

  .aboutDivCenter {
    padding: 5px 20px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .aboutCardsThreeFix {
    margin-top: 50px;
  }
}

.WelcomH2 {
  margin: 20px 0;
  color: var(--primary);
}

.testColor {
  color: #e5383b;
  color: #f34e51;
  color: #e07a7c;
  color: #e1b0b0;
  color: #e9ecef;
  color: #ffffff;
}

.contactContener {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 50px auto;
  background: var(--background1);
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  border: none;
  border-radius: 30px;
}

.contactContenerL {
  width: 70%;
  padding: 15px;
  margin: auto;
  padding-right: 25px;
}
.contactContenerR {
  width: 30%;
  background-image: url('https://pbs.twimg.com/media/FV2JfLkXwAEYRZl.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0 30px 30px 0;
  color: #fff;
}

@media (max-width: 767px) {
  .contactContenerR {
    background-image: url('https://artic.com.sa/wp-content/uploads/2020/08/kfd-2.jpg');
  }
}
.contactContenerR h3 {
  color: #fff;
}

.contactContenerL textarea {
  width: 500px;
  resize: none;
  height: 200px;
  width: 95%;
  margin-top: 10px;
  margin-bottom: -10px;
}
.conactFilter {
  width: 100%;
  height: 100%;
  background: var(--btn-gradient);
  background: -o-linear-gradient(
    bottom,
    var(--card-gradient),
    var(--card-gradient)
  );
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(var(--card-gradient)),
    to(var(--card-gradient))
  );
  background: linear-gradient(
    to top,
    var(--card-gradient),
    var(--card-gradient)
  );
  border-radius: 0 30px 30px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.contactInput {
  width: 100%;
  margin-top: 10px;
}
.contactText {
  width: 100%;
  font-size: 1rem;
}

.contactDiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
}
.contactDiv i {
  width: 30%;
}
.contactDiv div {
  width: 100%;
}

@media (max-width: 767px) {
  .contactContener {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    margin-bottom: 50px;
    border: none;
    border-radius: 30px;
  }
  .contactContenerL {
    width: 100%;
    margin: auto;
  }
  .contactContenerR {
    width: 100%;
    height: 280px;
  }
  .conactFilter {
    width: 100%;
    height: 100%;
    border-radius: 30px 30px 0 0;
  }
  .contactDiv {
    margin-bottom: 5px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .contactContenerR {
    width: 100%;
    border-radius: 30px 30px 0 0;
  }
  .contactSVG {
    width: 25px;
    margin-left: 20px;
  }
}

input {
  border: none;
  padding: 12px 15px;
  margin: 4px 5px;
  width: 100%;
  border-radius: 7px;
  color: var(--secondary);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  font-size: 16px;
  background-color: var(--background2);
  -webkit-box-shadow: inset 2px 2px 4px var(--input1),
    inset -2px -2px 4px var(--input2);
  box-shadow: inset 2px 2px 4px var(--input1), inset -2px -2px 4px var(--input2);
}

textarea {
  border: none;
  padding: 12px 15px;
  margin: 4px 5px;
  width: 100%;
  border-radius: 7px;
  color: var(--secondary);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  font-size: 16px;
  background-color: var(--background2);
  -webkit-box-shadow: inset 2px 2px 4px var(--input1),
    inset -2px -2px 4px var(--input2);
  box-shadow: inset 2px 2px 4px var(--input1), inset -2px -2px 4px var(--input2);
}
@media screen and (max-width: 768px) {
  input {
    font-size: 11px;
    padding: 10px 10px;
  }
  textarea {
    font-size: 11px;
    padding: 10px 10px;
  }
}
input:focus {
  outline: 0;
  -webkit-box-shadow: inset 4px 4px 4px rgba(187, 20, 20, 0.1),
    inset -4px -4px 4px rgba(187, 20, 20, 0.1);
  box-shadow: inset 4px 4px 4px rgba(187, 20, 20, 0.1),
    inset -4px -4px 4px rgba(187, 20, 20, 0.1);
}

textarea:focus {
  outline: 0;
  -webkit-box-shadow: inset 4px 4px 4px rgba(187, 20, 20, 0.1),
    inset -4px -4px 4px rgba(187, 20, 20, 0.1);
  box-shadow: inset 4px 4px 4px rgba(187, 20, 20, 0.1),
    inset -4px -4px 4px rgba(187, 20, 20, 0.1);
}

.mapContener {
  height: 350px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 50px auto;
  background: var(--background1);
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  border: none;
  border-radius: 30px;
  overflow: hidden;
}
.contactHedearContener {
  margin: 30px auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
  min-height: 250px;
}

.flexDiv {
  width: 32%;
  height: 100%;
  margin: auto auto;
}
.flexDiv {
  cursor: default;
}
.flexDiv h3 {
  margin-bottom: 20px;
}
.flexForm {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
  overflow: hidden;
  margin-right: -10px;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 0;
    height: 100%;
  }
  .flexDiv {
    width: 100%;
    margin-bottom: 50px;
  }
  .flexForm {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 0;
    padding-right: 10px;
  }
  .mapContener {
    margin-top: 0;
  }
}

.newsContener {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.newsContener input {
  margin-left: -1px;
}
@media (max-width: 767px) {
  .newsContener {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}
.btn-hover a {
  color: var(--secondary);
  cursor: pointer !important;
}

.btn-hover:hover {
  cursor: pointer !important;
}
.introBtnContener button {
  cursor: pointer !important;
}
.introBtnContener div {
  cursor: pointer !important;
}

.ServicesContener {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 50px;
}

.ServicesDivL {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: left;
  -webkit-align-items: left;
  -ms-flex-align: left;
  align-items: left;
  margin: 50px auto;
  width: 100%;
}
.ServicesDivLDiv {
  width: 100%;
  max-width: 700px;
  -webkit-transition: ease 0.2s;
  -o-transition: ease 0.2s;
  transition: ease 0.2s;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  background: var(--background1);
  border-radius: 30px;
  padding: 5px 0;
  border: none;
  margin-bottom: 20px;
  max-height: 90px;
  overflow: hidden;
}
.ServicesDivR {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: right;
  -webkit-justify-content: right;
  -ms-flex-pack: right;
  justify-content: right;
  margin: 50px auto;
  width: 100%;
  -webkit-justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-items: flex-end;
}
.ServicesDivRDiv {
  width: 100%;
  max-width: 700px;
  -webkit-transition: ease 0.2s;
  -o-transition: ease 0.2s;
  transition: ease 0.2s;
  display: block;
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  background: var(--background1);
  border-radius: 30px;
  padding: 15px;
  margin-bottom: 20px;
  border: none;
}

.ServicesTextContener {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  -webkit-box-align: right;
  -webkit-align-items: right;
  -ms-flex-align: right;
  align-items: right;
  width: 100%;
  cursor: pointer;
}
.ServicesTextContener p {
  margin: 0;
  font-size: 20px;
  margin-top: -5px;
}
.ServicesTextContener p i {
  cursor: pointer;
  margin: 5px 0 10px 0;
}
.ServicesImgContener {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.ServicesBorder {
  background-color: var(--background2);
  border: var(--background1) 10px solid;
  border-radius: 30px;
}
.ServicesBorder2 {
  background-color: var(--background2);
  border: var(--background1) 10px solid;

  border-radius: 30px;
}
.ServicesDivImg {
  max-width: 150px;
  max-height: 150px;
  position: absolute;
  margin-top: -5px;
  /* margin-right: -100px; */
  padding: 15px;
}

.none {
  display: none;
}

.down {
  height: 100px;
}

.maxCard {
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content;
  max-width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: ease 0.2s;
  -o-transition: ease 0.2s;
  transition: ease 0.2s;
  padding: 5px 0;
  border: none;
  margin-bottom: 20px;
}
.pmar {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pmar2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .ServicesTextContener {
    text-align: unset;
    padding: 0 15px;
  }
  .ServicesTextContener h4 {
    font-size: 16px;
  }
  .maxCard {
    text-align: center;
  }
  .maxCard h4 {
    font-size: 20px;
  }
}
@media screen and (max-width: 320px) {
  .ServicesTextContener h4 {
    font-size: 14px;
  }
}

.ProjectsContener {
  margin: auto auto;
  -webkit-transition: ease 0.2s;
  -o-transition: ease 0.2s;
  transition: ease 0.2s;
  display: block;
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  background: var(--background1);
  border-radius: 30px;
  cursor: pointer;
  border: none;
}

.ProjectsDiv {
  margin: 50px auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  max-height: 150px;
}
.cardivR {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 100%;
}
.cardivL {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  height: 100%;
  margin-top: -10px;
  width: 100%;
}
.ProjectsImg {
  border-radius: 30px;
}
/* ///// */

.hotelpernc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 200px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: auto auto;
  -webkit-transition: ease 0.2s;
  -o-transition: ease 0.2s;
  transition: ease 0.2s;
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  background: var(--background1);
  border-radius: 30px;
  cursor: pointer;
  overflow: hidden;
  border: none;
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .hotelpernc {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
  }
}
.hotelpernc:hover button {
  -webkit-box-shadow: 0 0 1px 0 var(--box-shadow);
  box-shadow: 0 0 1px 0 var(--box-shadow);
}
.hotelCardImg {
  width: 250px;
  height: 200px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -o-object-fit: cover;
  object-fit: cover;
  size: contain;
}
@media screen and (max-width: 768px) {
  .hotelCardImg {
    height: 150px;
    width: 100%;
  }
}
.hoteldetel {
  width: 70%;
  padding: 15px;
}

.hotelpricBorder {
  border-left: var(--quinary) 3px solid;
}
@media screen and (max-width: 768px) {
  .hotelpricBorder {
    border-left: unset;

    border-top: var(--quinary) 3px solid;
  }
}

.hotelpricBorderAr {
  border-right: var(--quinary) 3px solid;
}

@media screen and (max-width: 768px) {
  .hotelpricBorderAr {
    border-right: unset;
    border-top: var(--quinary) 3px solid;
  }
}

@media screen and (max-width: 768px) {
}
.MoreBtnContener {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}
.cardivL h3 {
  margin: 10px 0;
}
.cardivL h4 {
  margin: 10px 0;
}

/* ///////////// */
/* pupup */
/* ///////////// */

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
  z-index: 1001;
  -webkit-transform: var(--transition);
  -ms-transform: var(--transition);
  transform: var(--transition);
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 101234567898765432100;
}

.overlay {
  background: #464b569d;
}

.close-modal {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0px;
  padding: 0 4px;
  margin: 15px;
  z-index: 101234567898765432111;
  width: 25px;
  background-color: #ecf0f3;
  border-radius: 50%;
  border: 2px solid #564646b0;
}
@media screen and (max-width: 768px) {
  .close-modal {
    margin: 13px;
  }
}

.close-modal:hover {
  background-color: #cfd1d3;
  border-radius: 50%;
}
.modal-content-big {
  position: absolute;
  top: 63%;
  left: 50%;
  -webkit-transform: translate(-50%, -58%);
  -ms-transform: translate(-50%, -58%);
  transform: translate(-50%, -58%);
  width: 90%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  z-index: 101234567898765432122;
  min-width: 310px;
  border: none;
  background: var(--background1);
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  border: none;
  border-radius: 30px;
  padding: 5px 20px;
  max-width: 1050px;
}
/* @media screen and (max-width: 468px) {
  .modal-content-big {
    width: 100%;
    transform: unset;
    left: 0;
    bottom: 0;
    border-radius: 30px 30px 0 0;
  }
} */

.galleryImg {
  width: 100%;
  height: 100%;
  max-height: 500px;
  min-height: 500px;
  border-radius: 30px !important;
}

@media screen and (max-width: 768px) {
  .galleryImg {
    max-height: 300px;
    min-height: 300px;
  }
}
@media screen and (max-width: 378px) {
  .galleryImg {
    max-height: 250px;
    min-height: 250px;
  }
}
@media screen and (max-width: 328px) {
  .galleryImg {
    max-height: 220px;
    min-height: 220px;
  }
}
.galleryContener {
  max-height: 500px;
  height: 100%;
}

.abslooot {
  position: absolute;
  font-size: 20px;
  right: 8.5px;
  top: 8.5px;
  color: var(--btn-gradient);
}
.swiper-button-next,
.swiper-button-prev {
  color: var(--btn-gradient) !important;
}

/* loder */

.LoderContainer {
  height: 100%;
  min-height: 600px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  margin-top: 60px;
}

/* ///////////////// */

/* //  */
/* loder */
.containerLoder {
  width: 224px;
  height: 224px;
  margin: auto auto;
}
.containerLoder .box1Loder,
.containerLoder .box3Loder {
  border: 30px solid var(--primary);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  border-radius: 50px;
  display: block;
}

.containerLoder .box1Loder {
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  width: 224px;
  height: 96px;
  margin-top: 128px;
  margin-left: 0px;
  -webkit-animation: anime1 4s 0s forwards ease-in-out infinite;
  animation: anime1 4s 0s forwards ease-in-out infinite;
}
.containerLoder .box2Loder {
  width: 96px;
  border: 30px solid #fff;
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  border-radius: 50px;
  display: block;
  height: 96px;
  margin-top: 0px;
  margin-left: 0px;
  -webkit-animation: anime2 4s 0s forwards ease-in-out infinite;
  animation: anime2 4s 0s forwards ease-in-out infinite;
}
.containerLoder .box3Loder {
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  width: 96px;
  height: 96px;
  margin-top: 0px;
  margin-left: 64px;
  -webkit-animation: anime3 4s 0s forwards ease-in-out infinite;
  animation: anime3 4s 0s forwards ease-in-out infinite;
}
@-webkit-keyframes anime1 {
  0% {
    width: 224px;
    height: 96px;
    margin-top: 128px;
    margin-left: 0px;
  }
  12.5% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 0px;
  }
  25% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 0px;
  }
  37.5% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 0px;
  }
  50% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 0px;
  }
  62.5% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 0px;
  }
  75% {
    width: 96px;
    height: 224px;
    margin-top: 0px;
    margin-left: 0px;
  }
  87.5% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 0px;
  }
  100% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 0px;
  }
}
@keyframes anime1 {
  0% {
    width: 224px;
    height: 96px;
    margin-top: 128px;
    margin-left: 0px;
  }
  12.5% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 0px;
  }
  25% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 0px;
  }
  37.5% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 0px;
  }
  50% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 0px;
  }
  62.5% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 0px;
  }
  75% {
    width: 96px;
    height: 224px;
    margin-top: 0px;
    margin-left: 0px;
  }
  87.5% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 0px;
  }
  100% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 0px;
  }
}
@-webkit-keyframes anime2 {
  0% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 0px;
  }
  12.5% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 0px;
  }
  25% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 0px;
  }
  37.5% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 0px;
  }
  50% {
    width: 224px;
    height: 96px;
    margin-top: 0px;
    margin-left: 0px;
  }
  62.5% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 128px;
  }
  75% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 128px;
  }
  87.5% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 64px;
  }
  100% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 128px;
  }
}
@keyframes anime2 {
  0% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 0px;
  }
  12.5% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 0px;
  }
  25% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 0px;
  }
  37.5% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 0px;
  }
  50% {
    width: 224px;
    height: 96px;
    margin-top: 0px;
    margin-left: 0px;
  }
  62.5% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 128px;
  }
  75% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 128px;
  }
  87.5% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 128px;
  }
  100% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 128px;
  }
}
@-webkit-keyframes anime3 {
  0% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 128px;
  }
  12.5% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 128px;
  }
  25% {
    width: 96px;
    height: 224px;
    margin-top: 0px;
    margin-left: 128px;
  }
  37.5% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 128px;
  }
  50% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 128px;
  }
  62.5% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 128px;
  }
  75% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 128px;
  }
  87.5% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 128px;
  }
  100% {
    width: 224px;
    height: 96px;
    margin-top: 128px;
    margin-left: 0px;
  }
}
@keyframes anime3 {
  0% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 128px;
  }
  12.5% {
    width: 96px;
    height: 96px;
    margin-top: 0px;
    margin-left: 128px;
  }
  25% {
    width: 96px;
    height: 224px;
    margin-top: 0px;
    margin-left: 128px;
  }
  37.5% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 128px;
  }
  50% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 128px;
  }
  62.5% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 128px;
  }
  75% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 128px;
  }
  87.5% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 128px;
  }
  100% {
    width: 96px;
    height: 96px;
    margin-top: 128px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 768px) {
  .hero-copy {
    width: 95%;
    margin: auto auto;
  }
  .servicesHederContener {
    width: 95%;
    margin: auto auto;
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 0rem;
  left: 0rem;
  -webkit-animation: fadeIn 700ms ease-in-out 1s both;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer !important;
  border-radius: 50% !important;
  z-index: 1;
}
.scroll-to-top div {
  cursor: pointer !important;
  border-radius: 50% !important;
}
.btnUpI {
  color: #fff;
  font-size: 2rem;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.scroll-btn {
  border-radius: 50%;
  width: 65px;
  height: 65px;
}
.woot-widget-bubble {
  background-image: -o-linear-gradient(
    left,
    var(--primary),
    var(--btn-gradient),
    var(--btn-gradient),
    var(--primary)
  ) !important;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--primary)),
    color-stop(var(--btn-gradient)),
    color-stop(var(--btn-gradient)),
    to(var(--primary))
  ) !important;
  background-image: linear-gradient(
    to right,
    var(--primary),
    var(--btn-gradient),
    var(--btn-gradient),
    var(--primary)
  ) !important;
  -webkit-box-shadow: var(--card-shadow) !important;
  box-shadow: var(--card-shadow) !important;
  moz-transition: all 0.4s ease-in-out !important;
  -o-transition: all 0.4s ease-in-out !important;
  -webkit-transition: all 0.4s ease-in-out !important;
  transition: all 0.4s ease-in-out !important;
}

.aboutCardsContener2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: space-between;
  margin: 20px auto;
}

.abbbbautDiv1 {
  -webkit-flex-basis: 49%;
  -ms-flex-preferred-size: 49%;
  flex-basis: 49%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  -webkit-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
  background: var(--background1);
  border-radius: 30px;
}

@media screen and (max-width: 768px) {
  .aboutCardsContener2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .abbbbautDiv1 {
    margin: 20px auto;
  }
}

.shrkaVCOMD {
  /* background-color: red; */
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.shrkaVCOMDDDIV {
  /* background-color: blue; */
  width: 25%;
  /* max-height: 450px; */
  /* max-width: 200px; */
}

.shrkaVCOMM {
  width: 49%;
  margin: auto auto;
}

.PIMG {
  max-height: 200px;
  max-width: 200px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 10px;
  border: 3px solid var(--tertiary);
}

.hehdhdhdhd {
  margin: 20px auto;
  color: var(--primary);
}

@media screen and (max-width: 768px) {
  .shrkaVCOMD {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column-reverse;
  }
  .shrkaVCOMDDDIV {
    width: 100%;
  }
  .shrkaVCOMDDDIV img {
    width: 200px;
    height: 200px;
  }
  .shrkaVCOMM {
    width: 100%;
  }
}

.feature-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.feature-innerImg {
  padding: 10px;
  width: 100%;
  height: 100%;
}

.logocontner {
  height: 70px;
  width: 70px;
  padding: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .logocontner {
    height: 70px;
    width: 70px;
  }
  .pdfContner {
    -webkit-box-shadow: var(--card-shadow);
    box-shadow: var(--card-shadow);
    background: var(--background1);
    border-radius: 30px;
    margin: 20px auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 10px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .fllelelle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 25px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .pdfIcon {
    cursor: pointer;
    width: 70px;
    height: 70px;
    -webkit-box-shadow: var(--card-shadow);
    box-shadow: var(--card-shadow);
    background: var(--background1);
    border-radius: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 5px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .sddddwdwd {
    font-size: 22px;
  }

  @media screen and (max-width: 768px) {
    .sddddwdwd {
      font-size: 12px;
    }
    .sddddwdwd2 {
      font-size: 8px;
    }
    .servicesHederTitle {
      text-align: center;
      line-height: 3.5rem;
    }
    .wdwe {
      padding-top: 10px;
    }
    .feature-inner {
      padding-bottom: 40px;
      padding-top: 30px;
    }
  }
}
